<template>
  <picture data-test="banner-picture">
    <img
      ref="image"
      v-lazyload
      class="mobile-only banners__img"
      :class="['banners__img', 'lazy', $attrs.class, { loaded: !loadingImage }]"
      :data-src="mobileSrc"
      :title="bannerName"
      :alt="bannerName"
      v-bind="$attrs"
      data-test="banner-image"
      @load="onLoad"
    />
    <img
      ref="image"
      v-lazyload
      class="desktop-only banners__img"
      :class="['banners__img', 'lazy', $attrs.class, { loaded: !loadingImage }]"
      :data-src="desktopSrc"
      :title="bannerName"
      :alt="bannerName"
      v-bind="$attrs"
      data-test="banner-image"
      @load="onLoad"
    />
  </picture>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    bannerImage: {
      type: String,
      default: null,
    },
    bannerName: {
      type: String,
      required: true,
    },
    mobileSrc: {
      type: String,
      default: '',
    },
    desktopSrc: {
      type: String,
      default: '',
    },
    notLazy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loadingImage: true,
    }
  },
  methods: {
    onLoad() {
      if (this.$refs.image) {
        const { src, currentSrc } = this.$refs.image
        this.$emit('onloadedImage', currentSrc || src)
        this.loadingImage = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.banners__img {
  display: block;
  width: 100%;
  object-fit: contain;
  opacity: 0;

  &.loaded {
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out;
  }

  &.banners__img--mobile,
  &.banners__img--desktop {
    cursor: pointer;
  }

  &.banners__img--mobile {
    display: none;
  }
}

@media (max-width: $screen_tablet) {
  .banners__img {
    &.banners__img--desktop {
      display: none;
    }

    &.banners__img--mobile {
      display: block;
    }
  }
}
</style>
